<template>
    <div>
        <h2>{{ $t("tiers.proprietaires") }}</h2>

        <div class="box">
            <div class="col-12" v-if="form_message !== ''">
                <ErrorAlert :messageI18n="form_message" />
            </div>

            <CustomTable
                :id_table="id_table"
                ref="tableTiers"
                :items="filtredHorse"
                :busy.sync="table_busy"
                primaryKey="tiershorse_id"
                :hide_if_empty="true"
                :hrefsRoutes="config_table_hrefs"
				:display_action_button="!horseFicheReadOnly"
				:checkboxes="!horseFicheReadOnly"
            />
            
            <table class=" d-none d-md-block table table-hover mb-0">
                <thead>
                <tr>
                    <th></th>
                    <th>{{ $t("tiers.poucentage_propriete") }}</th>
                    <th v-if="hasFacturationAccess">{{ $t("tiers.poucentage_pensions") }}</th>
                    <th v-if="hasFacturationAccess">{{ $t("tiers.poucentage_frais") }}</th>
                    <th v-if="hasSaillieAccess">{{ $t("tiers.poucentage_contract") }}</th>
                    <th v-if="hasTrainerAccess">{{ $t("tiers.poucentage_entraineur") }}</th>
                    <th v-if="hasTrainerAccess">{{ $t("tiers.poucentage_jockey") }}</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">
                            {{ $t("global.total") }}
                        </td>
                        <td class="text-center">{{total_propriete}}</td>
                        <td v-if="hasFacturationAccess" class="text-center">{{total_pension}}</td>
                        <td v-if="hasFacturationAccess" class="text-center">{{total_frais}}</td>
                        <td v-if="hasSaillieAccess" class="text-center">{{total_contract}}</td>
                        <td v-if="hasTrainerAccess" class="text-center">{{total_entraineur}}</td>
                        <td v-if="hasTrainerAccess" class="text-center">{{total_jockey}}</td>
                    </tr>
                </tbody>
            </table>


            <b-card class="d-inline-block d-md-none">
                <h5> {{ $t("global.total") }} :</h5>
                <div class="d-inline-block">
                    <div>
                        <b class="mr-1">{{ $t("tiers.poucentage_propriete") }} :</b>
                        <span>{{total_propriete}}</span>
                    </div>
                    <div v-if="hasFacturationAccess">
                        <b class="mr-1">{{ $t("tiers.poucentage_pensions") }} :</b>
                        <span>{{total_pension}}</span>
                    </div>
                    <div v-if="hasFacturationAccess">
                        <b class="mr-1">{{ $t("tiers.poucentage_frais") }} :</b>
                        <span>{{total_frais}}</span>
                    </div>
                    <div v-if="hasSaillieAccess">
                        <b class="mr-1">{{ $t("tiers.poucentage_contract") }} :</b>
                        <span>{{total_contract}}</span>
                    </div>
                    <div v-if="hasTrainerAccess">
                        <b class="mr-1">{{ $t("tiers.poucentage_entraineur") }} :</b>
                        <span>{{total_entraineur}}</span>
                    </div>
                    <div v-if="hasTrainerAccess">
                        <b class="mr-1">{{ $t("tiers.poucentage_jockey") }} :</b>
                        <span>{{total_jockey}}</span>
                    </div>
                </div>
            </b-card>

            <!-- Ajouter/Modifier un tier -->
            <b-modal ref="modal" size="lg" hide-footer>
                <template v-slot:modal-title>
                    <template v-if="ajouter">
                        {{ $t("horse.ajouter_tiers_horse") }}
                    </template>

                    <template v-if="modifier">
                        {{ $t("horse.modifier_tiers_horse") }}
                    </template>
                </template>
                
                <HorseAjout 
                    :add-or-edit-ready.sync="addOrEditReady"
                    :disable-button.sync="disableButton"
                    ref="tiersHorseAjout"
                    :tiershorse_id="tiershorse_id"
                    :horse="horse"
                    :tiers_horse="tiers_horse"
					@submit="reload"
                />

                <b-button v-if="ajouter && addOrEditReady && !disableButton" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
                <b-button v-if="modifier && addOrEditReady && !disableButton" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button>
            </b-modal>

            <b-modal ref="modalLigneViergeMultiple" no-close-on-backdrop size="xl" hide-footer>
                <template v-slot:modal-title>
                    {{ $t("action.ajout_ligne_vierge") }}
                </template>
                <LigneViergeMultiple ref="ligne" :processing.sync="processing" :ready.sync="ready" :horse="horse" :preselect_infos="preselect_infos" :options="options"></LigneViergeMultiple>

                <div class="col-8 m-auto">

                    <template v-if="ready && !invoicedetails_id">
                        <b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
                        <b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter_et_nouveau") }}</b-button>
                    </template>
                </div>
            </b-modal>

            <HorseCloture ref="modal-cloture" @submit="reload"></HorseCloture>
        </div>

        <TiersHistorique ref="tiersHistorique" :horse_id="horse.horse_id" />
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from "EventBus";
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js";
    import Shutter from "@/mixins/Shutter.js"
    import THorseMixin from "@/mixins/Horse.js"

    export default {
        name: "HorseTiers",
        mixins: [TableAction, Navigation, Shutter, THorseMixin],
        props: ['horse', 'code_onboarding'],
        data () {
            return {
                ready: null,
                modal_loaded:null,
                options: null,
                preselect_infos: null,
                bons: null,
                invoicedetails_id: null,
                addOrEditReady: false,
                disableButton: false,
                processing: false,
                ajouter: false,
                modifier: false,
                tiershorse_id: null,
                tiers_horse: [],
                table_busy: true,
                form_message: '',
                total_contract : 0,
                total_frais : 0,
                total_pension : 0,
                total_propriete : 0,
                total_entraineur : 0,
                total_jockey : 0,
                config_table_hrefs: {
                    'tiers.tiers_rs': {
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers.tiers_id'
                        }
                    }
                },
                events_tab: {
                    'TableAction::goToAddTiersHorseTiers': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditTiersHorseTiers': (params) => {
                        this.addOrEdit(params.tiershorse_id)
                    },
                    'TableAction::goToAddBAF': (params) => {
                        this.addBAF(params)
                    },
                    'TableAction::goToDeleteTiersHorseTiers': (params) => {
                        this.$refs['modal-cloture'].open(this.horse.horse_id, params.tiers_id, params.tiershorse_id)
                    },
                    'DeleteTiersHorseHistoriqueTiersSuccess': () => {
                        this.loadHorseComponent()
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                await this.loadHorseComponent()
            },
            async loadHorseComponent() {
                this.table_busy = true
                this.tiers_horse = await this.loadTiersHeavyCurrentPart(this.horse.horse_id, this.hasSaillieAccess)
                
                this.form_message = '',
                this.total_contract = 0, this.total_frais = 0, this.total_pension = 0, this.total_propriete = 0, this.total_entraineur = 0, this.total_jockey = 0

                this.tiers_horse.forEach(tiers => {
                    this.total_contract += tiers.tiers_horse_part.tiershorsepart_contract
                    this.total_frais += tiers.tiers_horse_part.tiershorsepart_frais
                    this.total_pension += tiers.tiers_horse_part.tiershorsepart_pension
                    this.total_propriete += tiers.tiers_horse_part.tiershorsepart_propriete
                    this.total_entraineur += tiers.tiers_horse_part.tiershorsepart_entraineur
                    this.total_jockey += tiers.tiers_horse_part.tiershorsepart_jockey
                })

                if(this.total_contract > 100 || this.total_frais > 100 || this.total_propriete > 100) {
                    this.form_message = "tiers.erreur_poucentage_tableau"
                }

                this.table_busy = false
            },
            async addOrEdit(tiershorse_id) {
                this.processing = false
                this.addOrEditReady = false
                if(tiershorse_id) {
                    this.ajouter = false
                    this.modifier = true
                    this.tiershorse_id = tiershorse_id
                } else {
                    this.ajouter = true
                    this.modifier = false
                    this.tiershorse_id = null
                }

                this.$refs.modal.show()
            },
            async addBAF(tiers) {

                this.preselect_infos = {tiers :[]}
                for (const tier of tiers){
                    this.preselect_infos.tiers.push(tier.tiers)
                }
				this.modal_loaded = false
				this.invoicedetails_id = ""

				this.$refs["modalLigneViergeMultiple"].show()

				this.modal_loaded = true
            },
            async onSubmit(open_with_copy = false) {
				this.processing = true
				let invoice_details = await this.$refs.ligne.checkForm()

				if(invoice_details != false)
				{
					this.$refs["modalLigneViergeMultiple"].hide()
					this.successToast()

					if(open_with_copy === true) {
						this.invoicedetails_id = null
						//Dans le cas d'ajout de ligne, on me retourne un tableau de ligne, du coup je prend la 1ere
						this.copyInvoiceDetail(invoice_details.retour)
					}
				}
				this.processing = false
				this.ready = true
			},
            copyInvoiceDetail(invoicedetails) {

                this.modal_loaded = false

                this.modifier = false
                this.ajouter = true
                let tiers = []
                for (const invoice of invoicedetails){
                    tiers.push(invoice.tiers)
                }                
                this.preselect_infos = {
                    entity: invoicedetails[0].author,
                    tiers: tiers,
                    horse: invoicedetails[0].horse,
                    article: invoicedetails[0].articles,
                    invoice_details: invoicedetails[0],
                }
				this.$refs["modalLigneViergeMultiple"].show()

                this.modal_loaded = true
            },
            async checkForm() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.tiersHorseAjout.checkForm()
                    if(result) {
                        if(this.code_onboarding) {
                            this.successToast()
                            this.$refs.modal.hide()
                            this.ok()
                        }
                        else {
                            this.$refs.modal.hide()
                            this.loadHorseComponent()
                            this.$refs.tiersHistorique.loadTiersHistoriqueComponent()
                        }
                    } else {
                        this.processing = false
                    }
                }
            },
           async reload() {
                await this.$sync.force(true, true)
                EventBus.$emit("TableAction::stopSpin")
				this.init_component()
                this.$refs.tableTiers.resetCachedItems() 
				this.$refs.tiersHistorique.loadTiersHistoriqueComponent()
            }
        },
        computed: {
            hasTrainerAccess() {
            	return this.$store.state.userAccess.hasTrainerAccess
            },

            hasFacturationAccess() {
            	return this.$store.state.userAccess.hasFacturationAccess
            },

            hasSaillieAccess() {
            	return this.$store.state.userAccess.hasSaillieAccess
            },

			horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            },
            filtredHorse: function() {
                return this.tiers_horse.filter(tier => !tier.zero)
            },
            id_table() {
                return this.hasSaillieAccess ? "tiers_horse_tiers" : "tiers_horse_tiers_no_saillie"
            }
        },
        components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            HorseAjout : () => import('@/components/Tiers/HorseAjout'),
            HorseCloture : () => import('@/components/Tiers/HorseCloture'),
            TiersHistorique : () => import('@/components/Horse/TiersHistorique'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            LigneViergeMultiple: () => import('@/components/Invoice/LigneViergeMultiple'),
        }
    }
</script>
